import { InvoiceProcess } from './Constants'
import { signOut } from 'store/AuthState'
import * as deepcopy from 'deepcopy'
import store from 'store'

/**
 * Get the tenant of the user
 */
function getUserTenant() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo.metadata.tenantId
}

/**
 * Get the roles of the user
 */
function getUserRoles() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo.roles
}

/**
 * Get the types of roles
 */
function getTypesOfRoles() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const types = []

  Object.keys(userInfo.roles_metadata).forEach(key => {
    types.push(userInfo.roles_metadata[key].type?.toLowerCase())
  })
  return types
}

const handleError = (status, error) => {
  if (status === 401) {
    store.dispatch(signOut())
  } else {
    return {
      success: false,
      error: error
    }
  }
}

const handleRequestError = error => {
  return handleError(
    (error.response && error.response.status) || 500,
    (error.response && error.response.data) || error.response || error
  )
}

/**
 * Verify if permissions set contains some permission of the list
 */
function permissionSetContainsSome(permissionSet, permissions) {
  return permissionSet.some(p => permissions.includes(p))
}

/**
 * Verify if user has a role
 */
function containsUserRole(role) {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo.roles.includes(role)
}

/**
 * Get the roles of the user
 */
function getUserInfo() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo
}

/**
 * Get the login method
 */
function getLoginMethod() {
  let loginMethod = localStorage.getItem('login.method')
  return loginMethod
}

function getHashParameters(hash) {
  let hashParams = {}
  hash
    .substr(1)
    .split('&')
    .forEach(function (item) {
      let s = item.split('='),
        k = s[0],
        v = s[1] && decodeURIComponent(s[1])
      hashParams[k] = v
    })
  return hashParams
}
function cloneObject(obj, deep = false) {
  return deepcopy(obj)
}
function changeLoadingDiv(show) {
  let loadingDiv = document.getElementById('loadingDiv')
  if (loadingDiv) {
    if (show) {
      loadingDiv.style.display = 'block'
    } else {
      loadingDiv.style.display = 'none'
    }
  }
}
function showLoading(callback) {
  changeLoadingDiv(true)
  sessionStorage.setItem('isLoading', true)
  if (callback) {
    callback(true)
  }
}
function hideLoading(callback) {
  changeLoadingDiv(false)
  sessionStorage.removeItem('isLoading')
  if (callback) {
    callback(false)
  }
}

function checkExpiration(data) {
  let endDate = new Date(data.endDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  let currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  let timeBetween = new Date(endDate).getTime() - new Date(currentDate).getTime()
  let daysBetween = timeBetween / (1000 * 3600 * 24)
  let enabled = false
  if (daysBetween >= 0) {
    enabled = true
  }

  return {
    enabled: enabled,
    daysBetween: daysBetween,
    suscriptionType: data.suscriptionType
  }
}

function statusColor(status) {
  let color = 'black'
  status = status ? status.toUpperCase() : null
  switch (status) {
    case 'FAILED':
      color = '#bf0a0a'
      break
    case 'REJECTED':
      color = '#bf0a0a'
      break
    case 'REJECTEDNOTIFIED':
      color = '#bf0a0a'
      break
    case 'FAILEDTOSUBMIT':
      color = '#bf0a0a'
      break
    // case 'SENTTOARIBA':
    //   color = '#008000'
    //   break
    case 'COMPLETED':
      color = '#008000'
      break
    case 'MOVINGINVOICEAPP':
    case 'INVOICEEXCEPTION':
      color = '#af8f1c'
      break
    case 'INVOICESUPPLIEREXCEPTION':
      color = '#af8f1c'
      break
    case 'SENTTOEXTERNALSYSTEM':
      color = '#008000'
      break
    case 'SENTTOADDITIONALINBOX':
      color = '#008000'
      break
    case 'MANUALLYAPPROVED':
      color = '#008000'
      break
    case 'VALID':
      color = '#008000'
      break
    case 'NOTVALID':
      color = '#bf0a0a'
      break
    case 'IGNORED':
      color = '#999'
      break
    default:
      break
  }
  return color
}

function formatDate({ date, options = { timeZoneName: 'short' } }) {
  return new Date(date).toLocaleString(
    window.navigator.userLanguage || window.navigator.language,
    options
  )
}

function getExtensionFile(nameFile) {
  const newName = nameFile.replace('\\', '')
  const regex = /^([^\\]*)\.(\w+)$/
  var matches = newName?.match(regex)
  if (matches) {
    return matches[2].toLowerCase()
  }
}

function incrementAlphanumeric(str, increment = true) {
  const numPart = str.match(/(0?[1-9])+$|0?([1-9]+?0+)$/)[0]
  const strPart = str.slice(0, str.indexOf(numPart))
  const isLastIndexNine = numPart.match(/9$/)

  if (isLastIndexNine || strPart != null) {
    return strPart + numPart.replace(/\d+$/, n => (increment ? ++n : --n))
  } else {
    return strPart + '0' + numPart.replace(/\d+$/, n => (increment ? ++n : --n))
  }
}

function piiStatusColor(status) {
  let color
  switch (status) {
    case 'finished':
      color = '#008000'
      break
    case 'blocked':
      color = '#d2d818'
      break
    case 'obsolete':
      color = '#ff9800'
      break
    case 'integrated':
      color = '#008000'
      break
    case 'rejected':
      color = '#f44336'
      break
    default:
      color = 'black'
      break
  }
  return color
}

function checkErrorOrWarning(validationError, validated = false) {
  if (validated) return ''

  if (typeof validationError === 'string' && validationError !== '') return 'error'

  if (Array.isArray(validationError) && validationError.length > 0) {
    let warning = false
    let validation
    for (validation of validationError) {
      if (validation.type && validation.type === 'warning') {
        warning = true
      } else {
        return 'error'
      }
    }
    return warning ? 'warning' : 'error'
  }
  return ''
}

const escapeRegExp = expr => {
  return expr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function encodeUrl(url) {
  return encodeURIComponent(url)
    .replace(new RegExp(escapeRegExp('!'), 'g'), '%21')
    .replace(new RegExp(escapeRegExp("'"), 'g'), '%27')
    .replace(new RegExp(escapeRegExp('('), 'g'), '%28')
    .replace(new RegExp(escapeRegExp(')'), 'g'), '%29')
    .replace(new RegExp(escapeRegExp('*'), 'g'), '%2A')
    .replace(new RegExp(escapeRegExp('+'), 'g'), '%20')
}

function decodeUrl(url) {
  return decodeURIComponent(url)
    .replace(new RegExp(escapeRegExp('%21'), 'g'), '!')
    .replace(new RegExp(escapeRegExp('%27'), 'g'), "'")
    .replace(new RegExp(escapeRegExp('%28'), 'g'), '(')
    .replace(new RegExp(escapeRegExp('%29'), 'g'), ')')
    .replace(new RegExp(escapeRegExp('%2A'), 'g'), '*')
    .replace(new RegExp(escapeRegExp('%20'), 'g'), '+')
}

function getInvoiceProcess(appConfig) {
  const process = {}
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(InvoiceProcess)) {
    if (appConfig.params[key] && appConfig.params[key].enabled) {
      process[key] = value
    }
  }
  process['ocr-global'] = InvoiceProcess['ocr-global']
  return process
}

function countDecimals(value) {
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1]?.length || 0
  }
  return 0
}

export function maxDecimalAndSummation(values) {
  let summation = values.reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const maxDecimals = values.map(currentValue => {
    return countDecimals(Number(currentValue ?? 0))
  })

  const maxDecimalNo = maxDecimals.length > 0 ? Math.max(...maxDecimals) : 0
  summation = Number(summation.toFixed(maxDecimalNo))

  return { summation, maxDecimalNo }
}

function midPointBoundingBox(boundingBox) {
  const height = boundingBox?.Height ?? 0
  const width = boundingBox?.Width ?? 0
  const left = boundingBox?.Left ?? 0
  const top = boundingBox?.Top ?? 0

  const midHeight = height / 2
  const midWidth = width / 2
  return { x: midWidth + left, y: midHeight + top }
}

function getDistance(points) {
  const { xA, yA, xB, yB } = points
  const xDiff = xA - xB
  const yDiff = yA - yB

  return Math.sqrt(xDiff * xDiff + yDiff * yDiff)
}

/*
Create headers
*/
function createHeaders() {
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`
  }

  return headers
}

function isObject(something) {
  return typeof something === 'object'
}
function getInvoiceProcessArray(appConfig) {
  const process = []
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(InvoiceProcess)) {
    if (appConfig.params[key] && appConfig.params[key].enabled) {
      process.push({ name: value, id: key })
    }
  }

  return process
}

function getSubdomainFromUrl(hostname) {
  if (!hostname) return null

  const parts = hostname.split('.')
  if (parts.length <= 2) return null

  const subdomain = parts[0]
  const internalSubdomains = new Set(['apps', 'apps-eu', 'dev', 'stage', 'relishiq', 'platform'])

  return internalSubdomains.has(subdomain) ? null : subdomain
}

export {
  checkErrorOrWarning,
  checkExpiration,
  cloneObject,
  containsUserRole,
  createHeaders,
  decodeUrl,
  encodeUrl,
  formatDate,
  getDistance,
  getExtensionFile,
  getHashParameters,
  getInvoiceProcess,
  getLoginMethod,
  getTypesOfRoles,
  getUserInfo,
  getUserRoles,
  getUserTenant,
  handleError,
  handleRequestError,
  hideLoading,
  incrementAlphanumeric,
  isObject,
  midPointBoundingBox,
  permissionSetContainsSome,
  piiStatusColor,
  showLoading,
  statusColor,
  getInvoiceProcessArray,
  getSubdomainFromUrl
}
