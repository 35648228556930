import { ApiPii, ApiDataAssureSql } from 'utils/Constants'
import axios from 'axios'
import { createHeaders } from 'utils/functions'

async function getSuppliersData(pagination, sorting, filters) {
  const url = `${ApiDataAssureSql.EndPoint}/core/v1/pii/suppliers`
  const headers = createHeaders()
  const params = {
    pageSize: pagination.pageSize,
    pageNumber: pagination.pageNumber
  }
  if (sorting) {
    params.sortByKey = sorting.field
    params.sortDirection = sorting.sort.toUpperCase()
  }
  if (filters.length) {
    params.filters = filters
      .map(f => `${f.fieldName}:${f.operatorValue}:${f.filterValue}`)
      .join('|')
  }
  const response = await axios({
    method: 'GET',
    url,
    headers,
    params
  })

  if (response?.data?.suppliers) {
    response.data = {
      ...response.data,
      suppliers: response.data.suppliers.map(piiRecord => ({
        ...piiRecord,
        tenantId: piiRecord.client,
        appId: piiRecord.environment
      }))
    }
  }
  return response.data
}

async function getSupplierHistory(tenantId, environment, supplierId) {
  const url = `${ApiPii.EndPoint}/tenant/${tenantId}/environment/${environment}/supplier/${supplierId}/getSupplierAllHistory`
  const headers = createHeaders()
  const response = await axios({
    method: 'GET',
    url,
    headers
  })

  return response?.data?.data
}

async function updateSupplierEmail(haskKey, validationId, email) {
  const url = `${ApiPii.EndPoint}/pii/client/${haskKey}/task/${validationId}/supplierData`
  const headers = createHeaders()
  return axios({
    method: 'put',
    url,
    headers,
    data: { email }
  })
}

async function rejectPiiValidationManually(haskKey, validationId, message) {
  const url = `${ApiPii.EndPoint}/pii/client/${haskKey}/task/${validationId}/manual-rejection`
  const headers = createHeaders()
  return axios({
    method: 'post',
    url,
    headers,
    data: { message }
  })
}

async function resendSupplierInvitation(tenantId, appId, validationId) {
  const url = `${ApiPii.EndPoint}/sendPiiFormInvitation`
  const headers = createHeaders()
  return axios({
    method: 'post',
    url,
    headers,
    data: { tenantId, appId, taskId: validationId }
  })
}

export {
  getSuppliersData,
  getSupplierHistory,
  rejectPiiValidationManually,
  resendSupplierInvitation,
  updateSupplierEmail
}
